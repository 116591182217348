import debounce from 'lodash/debounce';
import Swiper, { Pagination } from 'swiper';

export const initPlatform = () => {
  const body = document.querySelector('body');
  const principlesNav = document.querySelectorAll('.slidenav-item');
  const carouselBody = document.querySelector('.points-carousel__body');
  const designPrinciples = new Swiper('.principles-swiper', {
    modules: [Pagination],
    speed: 500,
    hashNavigation: {
      replaceState: true,
      watchState: true
    },
    autoHeight: false,
    spaceBetween: 0,
    loop: false,
    pagination: {
      el: '.slidenav',
      clickable: true,
      bulletActiveClass: 'is-active',
      bulletClass: 'slidenav-item',
      renderBullet: function (index, classNm) {
        const el = principlesNav[index];
        el.classList.add(classNm);
        return el.outerHTML;
      }
    },
    on: {
      setTranslate: function (swiperInst, progress) {
        carouselBody.classList.add('transitioning');
        // if (progress !== 0 && progress !== 1)
        // else carouselBody.classList.remove('transitioning');
      },
      progress: function (swiperInst, progress) {
        if (progress !== 0 && progress !== 1)
          carouselBody.classList.add('transitioning');
        // else carouselBody.classList.remove('transitioning');
      },
      transitionStart: function () {
        console.log('transitionstart');
        carouselBody.classList.add('transitioning');
      },
      transitionEnd: function (e) {
        carouselBody.classList.remove('transitioning');
        console.log('end');
      }
    }
  });

  const resizeObserver = new ResizeObserver((entries) => {
    console.log(designPrinciples.width);
    debounce(() => designPrinciples.update(), 400);
  });
  resizeObserver.observe(body);
};
