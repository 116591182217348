export const initContact = () => {
  // function formSubmit(e) {
  //   var form = e.target;
  //   var button = document.getElementById('submit');

  //   var status = document.getElementById('my-form-status');

  //   function resetForm() {
  //     form.reset();
  //   }
  //   function success() {
  //     form.reset();
  //     button.style = 'display: none';
  //     status.innerHTML = 'Thanks!';
  //     gtag('event', 'conversion', {
  //       send_to: 'AW-592701248/hbiDCISFs-MBEMDOz5oC'
  //     });
  //   }
  //   function error() {
  //     status.innerHTML = 'Oops! There was a problem.';
  //   }

  //   e.preventDefault();

  //   var data = new FormData(form);

  //   var xhr = new XMLHttpRequest();
  //   var url = 'https://formspree.io/xpvaqpbx';
  //   xhr.open('POST', url, true);
  //   xhr.setRequestHeader('Accept', 'application/json');
  //   xhr.onreadystatechange = function () {
  //     if (xhr.readyState !== XMLHttpRequest.DONE) return;
  //     if (xhr.status === 200) {
  //       success(xhr.response, xhr.responseType);
  //     } else {
  //       error(xhr.status, xhr.response, xhr.responseType);
  //     }
  //   };
  //   xhr.send(data);
  // }

  // function mailSubmit(e) {
  //   var form = e.target;
  //   var status = document.getElementById('mail-status');

  //   function success() {
  //     form.reset();
  //     status.classList.add('text-black');
  //     status.innerHTML = 'Thanks!';
  //   }
  //   function error() {
  //     status.classList.add('text-red');
  //     status.innerHTML = 'Oops! There was a problem.';
  //   }

  //   e.preventDefault();

  //   var data = new FormData(form);

  //   var xhr = new XMLHttpRequest();
  //   var url = 'https://formspree.io/xvepzrlm';
  //   xhr.open('POST', url, true);
  //   xhr.setRequestHeader('Accept', 'application/json');
  //   xhr.onreadystatechange = function () {
  //     if (xhr.readyState !== XMLHttpRequest.DONE) return;
  //     if (xhr.status === 200) {
  //       success(xhr.response, xhr.responseType);
  //     } else {
  //       error(xhr.status, xhr.response, xhr.responseType);
  //     }
  //   };
  //   xhr.send(data);
  // }

  function contactSubmit(e) {
    e.preventDefault();

    const form = e.target;
    const status = document.getElementById('form-status');
    const message = document.getElementById('form-message');
    const errorMsg = document.getElementById('form-error');
    const contactAgain = document.getElementById('contactAgain');
    contactAgain.addEventListener('click', successReset);

    function toggleView(showEl, hideEl) {
      hideEl.classList.add('opacity-0', 'invisible');
      showEl.classList.remove('opacity-0', 'invisible');
    }
    function success() {
      form.reset();
      toggleView(status, form);

      // gtag('event', 'conversion', {
      //   send_to: 'AW-592701248/uS1YCNWxuOIBEMDOz5oC'
      // });
    }
    function error() {
      // errorMsg.innerHTML = 'Oops! There was a problem.';
      errorMsg.classList.remove('invisible', 'opacity-0');
    }

    function successReset() {
      form.reset();
      toggleView(form, status);
    }

    var data = new FormData(form);
    var xhr = new XMLHttpRequest();
    var url = 'https://formspree.io/mgepvwva';
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = function () {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        const base = window.location.hostname;
        window.location.assign(`//${base}/thankyou`);
        // success(xhr.response, xhr.responseType);
      } else {
        error(xhr.status, xhr.response, xhr.responseType);
      }
    };
    xhr.send(data);
  }

  // var formspreeForms = document.querySelectorAll('form.formspree');
  // for (var i = 0; i < formspreeForms.length; i++) {
  //   formspreeForms[i].addEventListener('submit', formSubmit);
  // }

  // var mailForms = document.querySelectorAll('form.mail-form');
  // for (var i = 0; i < mailForms.length; i++) {
  //   mailForms[i].addEventListener('submit', mailSubmit);
  // }

  var contactForms = document.querySelectorAll('form.long-contact');
  for (var i = 0; i < contactForms.length; i++) {
    contactForms[i].addEventListener('submit', contactSubmit);
  }
};
