import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from 'gsap/CustomEase';
gsap.registerPlugin(CustomEase, ScrollTrigger);

import { initHome } from './home';
import { initContact } from './contact';
import { initPlatform } from './platform';

initContact();

const body = document.querySelector('body');

if (body.classList.contains('home')) initHome();
if (body.classList.contains('platform')) initPlatform();

if (body.classList.contains('about')) {
  ScrollTrigger.matchMedia({
    '(min-width:768px)': () => {
      ScrollTrigger.create({
        trigger: '#aboutHeaderImg',
        start: 'top top+=70px',
        pin: true,
        endTrigger: '#aboutIntro',
        end: 'bottom-=64px center+=64'
      });
    }
  });
}

const initScrollTriggers = () => {
  const slideIns = gsap.utils.toArray('.hero-panel .container, .slide-in');
  slideIns.forEach((item, i) => {
    gsap.from(item, {
      scrollTrigger: {
        trigger: item,
        toggleActions: 'play pause resume pause',
        start: 'top bottom-=100px'
      },
      y: '+=50',
      opacity: 0,
      duration: 1,
      ease: 'sine.inOut',
      stagger: 0.5
    });
  });
};
initScrollTriggers();
