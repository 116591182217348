import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from 'gsap/CustomEase';
gsap.registerPlugin(CustomEase, ScrollTrigger);
import Swiper, { Navigation, Scrollbar } from 'swiper';
const swiper = new Swiper('.news-swiper', {
  modules: [Navigation, Scrollbar],
  slidesPerView: 1.25,
  spaceBetween: 24,
  centeredSlides: true,
  loop: true,
  speed: 500,
  autoplay: true,
  navigation: {
    prevEl: '#swiper-prev',
    nextEl: '#swiper-next'
  },
  breakpoints: {
    480: {
      slidesPerView: 1.75,
      spaceBetween: 36
    }
  }
});

export const initHome = () => {
  const cpgSources = [
    {
      id: '#distributors',
      order: 1,
      direction: 250
    },
    {
      id: '#consumers',
      order: 4,
      direction: 315
    },
    {
      id: '#retailers',
      order: 2,
      direction: 355
    },
    {
      id: '#product',
      order: 0,
      direction: 90
    },
    {
      id: '#sales',
      order: 3,
      direction: 115
    },
    {
      id: '#multilevelsales',
      order: 6,
      direction: 30
    },
    {
      id: '#newstrends',
      order: 5,
      direction: 176
    }
  ];
  const degToRad = (d) => (d * Math.PI) / 180;
  const calcDistance = (deg) => {
    const distance = 200;
    const x = distance * Math.cos(degToRad(deg));
    const y = distance * Math.sin(degToRad(deg));
    return {
      x: `+=${x}`,
      y: `+=${y}`
    };
  };

  const vid = document.querySelector('#homeHeroVideo');
  vid.addEventListener('canplay', () => vid.classList.remove('opacity-0'));
  const minidiagramSVG = document.querySelector('#cpg-minidiagram');
  const viewBoxAspect = [522, 370];
  const calcViewBox = (padding) => {
    const offset = padding / -2;
    return `${offset} ${offset} ${viewBoxAspect
      .map((v) => v + padding)
      .join(' ')}`;
  };

  const createTimeline = (config) => {
    const mdtl = gsap.timeline({
      defaults: {
        duration: 1
      },
      scrollTrigger: {
        trigger: '#overview',
        start: 'top center',
        endTrigger: '#overview-end',
        end: 'center center+=160',
        ease: 'power2.out',
        id: '#diagram',
        ...config
      }
    });

    cpgSources.forEach((source, i) => {
      mdtl.from(
        source.id,
        {
          opacity: 0,
          ...calcDistance(source.direction),
          label: 'establish',
          duration: 2
        },
        0.1 * i
      );
    });
    mdtl
      .from('#cpg-minidiagram #lines path ', { opacity: 0 }, 2)
      .to('#cpg-minidiagram ', { opacity: 1 });
  };

  const minidiagram = document.querySelector('#minidiagram');

  ScrollTrigger.create({
    trigger: '#platform',
    start: 'bottom bottom+=100px',
    endTrigger: '#overview',
    end: 'top top-=50px',
    id: '#video',
    toggleClass: {
      targets: '.dataloop-bg',
      className: 'active'
    }
  });

  const miniDiagramScrollTriggerDefaults = {
    trigger: '#minidiagram > div',
    start: 'top+=50 top',
    end: 'bottom-=230px center',
    markers: false
  };
  ScrollTrigger.create({
    ...miniDiagramScrollTriggerDefaults
  });
  createTimeline();
};
